a.ngx-mdc-icon {
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ngx-mdc-icon--inline {
  font-size: inherit;
  height: inherit;
  line-height: inherit;
  width: inherit;
}
