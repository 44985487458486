@use "./theme"; // setting theme colors/settings
@use "./media-queries";
@use "@material/theme/color-palette" as material-color;
@use "@material/theme/_variables" as theme-variables; // getting theme colors/settings

html {
  font-size: 16px;

  @media #{media-queries.$mobile} {
    font-size: 14px;
  }
}

body {
  background-color: theme-variables.$background; // visible in desktopsize
  margin: 0;
}

body[no-mobile-menu] .topbar__menu { // a component can set attribute no-mobile-menu on body to hide the hamburger menu
  display: none;
}

