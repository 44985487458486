@use "./fonts";

@use "@material/theme" with (
  $primary: #1C3F94,    // Text color
  $secondary: #E69225,  // used for navigation buttons
  $background: #F4F6F8, // the canvas
  $surface: #FFF        // used as background for card, menu, list 
);

// colors
$tertiary: #72CDF4;     // lighter blue, used for accents in borders
$information: #BBC5DF;  // message/info-ish panels
$link: #E4E8F2;         // light blue, used for background of download links
$background-control: #E3E5E7;
$indicator-task-color: #AD027A;

//typography
$body1-letter-spacing: 0.03125em;

@use "@material/typography" with (
  $font-family: fonts.$font-family-primary,

  $styles-headline1: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-headline2: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-headline3: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-headline4: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-headline5: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-headline6: (
    font-family: fonts.$font-family-secondary,   
    color: theme.$primary
  ),

  $styles-button: (
    font-family: fonts.$font-family-secondary,
    font-size: inherit,
    text-transform: none // Not the default uppercase
  )
);

