@use "./theme"; // setting theme colors/settings
@use "./media-queries";
@use "@material/theme/color-palette" as material-color;
@use "@material/theme/_variables" as theme-variables; // getting theme colors/settings
@use "@material/button";

.button--main-action {
  @include button.container-fill-color(theme-variables.$secondary);
  @include button.icon-color(theme-variables.$surface);
  @include button.ink-color(theme-variables.$surface);
}

.button--sub-action {
  @include button.icon-color(theme-variables.$secondary);
  text-align: start; // if the text contains 2 lines make sure that the text is left aligned

  @media #{media-queries.$mobile} {
    letter-spacing: theme.$body1-letter-spacing;
  }
}

.button--link-action {
  @include button.icon-color(theme-variables.$secondary);
  @include button.container-fill-color(theme.$link);
  padding-right: 16px;

  @media #{media-queries.$mobile} {
    letter-spacing: theme.$body1-letter-spacing;
  }
}
