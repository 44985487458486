@use "./theme"; // setting theme colors/settings
@use "./media-queries";
@use "@material/typography/_index" as typography; // getting typegraphy settings

$max-width-header-desktop: 1020px;
$max-width-content-desktop: 1440px;

// At a global level because these styles are used in content projection parts
.page__banner-text {
  position: absolute;
  padding-top: 0;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  top: 48px;
  left: 48px;
  width: 50%;

  h1 {
    @include typography.typography(headline4);
  }

  @media #{media-queries.$tablet} {
    top: 16px;
    left: 16px;
    width: 60%;

    h1 {
      @include typography.typography(headline5);
    }
  }

  @media #{media-queries.$mobile} {
    display: none;
  }

  @media #{media-queries.$desktop} {
    left: calc((100vw - #{$max-width-header-desktop} - 64px )/2); // align with logo in header
  }

  @media (min-width: $max-width-content-desktop) {
    left: 186px; // align text with logo in header 210-24
  }
}

.page__banner-text:empty {
  display: none;
}



