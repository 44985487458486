@use "./theme"; // setting theme colors/settings
@use "./media-queries";
@use "@material/theme/color-palette" as material-color;
@use "@material/theme/_variables" as theme-variables; // getting theme colors/settings

//------------------------------------------------------------------------------------------------- parts
.page-content__title {
  font-weight: bold;
}

.page-content__container {
  margin: 1em 0;
}

.page-content__tabbar {
  background-color: theme-variables.$surface;
  margin-top: 1px;
  border-bottom: 1px solid lightgrey;
}
