@use "./theme"; // setting theme colors/settings
@use "./media-queries";
@use "./icons";
@use "./fonts";
@use "@material/theme/color-palette" as material-color;
@use "@material/theme/_variables" as theme-variables; // getting theme colors/settings

$less-important-color: #00000061;

/* Override height of last event here instead of component scss because this exceeds the component style*/
au-timeline-event:last-of-type .chronology-content:before {
  height: calc(100% - 54px);
}

.chronology-content {
  font-family: fonts.$font-family-secondary;
}

.chronology-content__event-description {
  font-family: fonts.$font-family-primary;
}

.chronology-content {
  .date-month {
    color: $less-important-color;
  }

  .date-year {
    color: $less-important-color;
  }
}

.chronology-content__sub-title {
  color: $less-important-color;
}

.chronology-content__indicator-container {
  display: flex;
  flex-direction: row;
}

.chronology-content__indicator-task-pending {
  background-color: theme.$indicator-task-color;
  color: white;
  padding: 10px 10px;
  display: inline-block;
}

.chronology-content__indicator-task-handled {
  background-color: white;
  color: $less-important-color;
  border: 1px solid #707070;
  padding-top: 10px;
  padding-left: 6px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: inline-block;

  &:before {
    @extend %material-icons;
    content: "check";
    position: relative;
    margin-right: 2px;
    top: 6px;
    color: theme.$indicator-task-color;
    line-height: 0;
  }
}

.chronology-content__indicator-document {
  background-color: #3E5CA4;
  color: white;
  padding: 10px 10px;
  display: inline-block;
}

.chronology-content__indicator-text {
  color: theme-variables.$primary;
  padding-left: 10px;
  text-align: right;
  align-self: center;
  flex-grow: 1;
}
