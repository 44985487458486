@use "sass:string";

$font-family-primary: string.unquote("Open sans, Arial, sans-serif");
$font-family-secondary: string.unquote("Hero, Century Gothic, CenturyGothic, AppleGothic, sans-serif");

@font-face {
    font-family: "Hero";
    src: url("/assets/fonts/hero.woff2") format("woff2"),
         url("/assets/fonts/hero.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}